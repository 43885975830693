import axios from 'axios';
import AuthService from 'src/shared/api/auth/AuthService';
import TokenService from 'src/shared/api/token/TokenService';
import { PROJECT_URL } from 'src/shared/config';

export const ApiProjects2 = axios.create({
  baseURL: PROJECT_URL,
});

ApiProjects2.interceptors.request.use(
  config => {
    const accessToken = TokenService.getAccessToken();
    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

ApiProjects2.interceptors.response.use(
  res => {
    return res;
  },
  async err => {
    const originalConfig = err.config;
    if (err?.response?.status === 401 && originalConfig.url.includes('/refresh')) {
      TokenService.removeTokens();
    }

    if (!originalConfig.url.includes('/login') && err.response) {
      const refresh_token = TokenService.getRefreshToken();

      if (!refresh_token) {
        TokenService.removeTokens();

        return Promise.reject(err);
      }

      if (err.response.status === 401 && !originalConfig?._retry) {
        originalConfig._retry = true;

        try {
          const { data } = await AuthService.refresh({ refresh_token });

          TokenService.setTokens(data);
          return ApiProjects2(originalConfig);
        } catch (_error) {
          return Promise.reject(_error);
        }
      }
    }

    return Promise.reject(err);
  },
);
