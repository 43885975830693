import { Dispatch, SetStateAction } from 'react';
import { BatchRowsData, BatchRowsRes } from 'src/shared/api/table/types';
import { getRandomInt, isEmptyDataObject, setError, setSuccess } from 'src/shared/lib/utils';
import { TableSettingsForm } from 'src/shared/store/projects/types';
import { initNoticeData } from 'src/shared/store/table/constants';
import { ListItem } from 'src/shared/store/table/types';

export const createEmptyRow = (tableSettings: TableSettingsForm | null): ListItem => {
  const newRow = {};

  tableSettings?.formStructure.forEach(({ columnName, columnType }) => {
    if (columnType === 'actions') {
      newRow[columnName] = { isEdit: false, isRemoved: false, newId: getRandomInt(9999999) };
    }
    if (columnType === 'text') {
      newRow[columnName] = '';
    }
  });

  return newRow;
};

type ParamsType = {
  initialData: string;
  clearlyData: ListItem[];
};

export const createRowsBatchData = (params: ParamsType): BatchRowsData => {
  const { initialData, clearlyData } = params;

  const data: BatchRowsData = { create: [], delete: [], update: [] };

  const initial: ListItem[] = !initialData || initialData === 'null' ? [] : JSON.parse(initialData);

  initial.forEach(item => {
    const newItem = clearlyData.find(el => el?.id === item?.id);

    if (!newItem) {
      data.delete = [...data.delete, +item?.id];
    }

    if (newItem && JSON.stringify(newItem) !== JSON.stringify(item)) {
      data.update = [
        ...data.update,
        {
          data: newItem,
          id: +newItem?.id,
        },
      ];
    }
  });

  clearlyData.forEach(el => {
    if (!el.id && !isEmptyDataObject(el)) {
      data.create = [...data.create, el];
    }
  });

  return data;
};

type ParamsResType = {
  initialData: string;
  data: BatchRowsRes;
  setTableData: Dispatch<SetStateAction<ListItem[]>>;
  setInitialData: Dispatch<SetStateAction<string>>;
};

export const parseRowsBatchDataRes = (params: ParamsResType) => {
  const { initialData, data, setInitialData, setTableData } = params;

  const { createResults, deleteResults, updateResults } = data;
  const errors = { ...initNoticeData };
  const success = { ...initNoticeData };
  let init: ListItem[] = !initialData || initialData === 'null' ? [] : JSON.parse(initialData);

  if (deleteResults.length) {
    deleteResults.forEach(res => {
      if (res.success) {
        success.delete += 1;
        init = init.filter(el => el.id !== res.data.id);
      } else {
        errors.delete += 1;
      }
    });
  }

  if (createResults.length) {
    createResults.forEach(res => {
      if (res.success) {
        success.create += 1;
        init.push(res.data);
      } else {
        errors.create += 1;
      }
    });
  }

  if (updateResults.length) {
    updateResults.forEach(res => {
      if (res.success) {
        success.update += 1;
        init = init.map(el => (el.id === res.data.id ? res.data : el));
      } else {
        errors.update += 1;
      }
    });
  }

  const list = init.reduce((filtered: ListItem[], item: ListItem) => {
    if (Object.keys(item)?.length > 1) {
      filtered.push({
        ...item,
        actions: { isEdit: false, isRemoved: false },
      });
    }
    return filtered;
  }, []);

  setTableData(list);
  setInitialData(JSON.stringify(init));

  if (errors.update || errors.create || errors.delete) {
    setError(
      `Ошибка при ${errors.delete ? `удалении(${errors.delete})` : ''} ${errors.create ? `создании(${errors.create})` : ''} ${errors.update ? `изменении(${errors.update})` : ''}`,
    );
  }
  if (success.update || success.create || success.delete) {
    setSuccess(
      `Успешно ${success.delete ? `удалились(${success.delete})` : ''} ${success.create ? `создалось(${success.create})` : ''} ${success.update ? `изменилось(${success.update})` : ''}`,
    );
  }
};
