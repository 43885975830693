import React, {
  createContext,
  FC,
  ReactNode,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import TableService from 'src/shared/api/table/TableService';
import TokenService from 'src/shared/api/token/TokenService';
import { TABLE_SOCKET_URL } from 'src/shared/config';
import { setError } from 'src/shared/lib/utils';
import { tableContextDefaultState } from 'src/shared/store/table/constants';
import {
  createEmptyRow,
  createRowsBatchData,
  parseRowsBatchDataRes,
} from 'src/shared/store/table/helpers';
import { ITableContext, ListItem, SortItem } from 'src/shared/store/table/types';
import { UserContext } from 'src/shared/store/user';

export const TableContext = createContext<ITableContext>(tableContextDefaultState);

interface ITableProviderProps {
  children: ReactNode;
}

export const TableProvider: FC<ITableProviderProps> = ({ children }) => {
  const socket = useRef<WebSocket | null>(null);

  const { tableData: _tableData, isSaveLoading: _isSaveLoading } = tableContextDefaultState;
  const { tableSettings } = useContext(ProjectsContext);
  const { user } = useContext(UserContext);

  const [tableData, setTableData] = useState<ListItem[]>(_tableData);
  const [isSaveLoading, setIsSaveLoading] = useState<boolean>(_isSaveLoading);
  const [initialData, setInitialData] = useState<string>('');
  const [sort, setSort] = useState<SortItem | null>();
  const [search, setSearch] = useState<string>('');

  useEffect(() => {
    if (!tableSettings?.formStructure?.length) {
      return;
    }

    if (user?.isDeveloper) {
      const arr: ListItem[] = Array.from({ length: 100 }).map((_, i) => {
        const obj: ListItem = {};

        tableSettings.formStructure.forEach(({ columnLabel, columnType, columnName }) => {
          if (columnType === 'text') obj[columnName] = `${columnLabel} - ${i + 1}`;
          if (columnType === 'number') obj[columnName] = i + 1;
          if (columnType === 'floatNumber') obj[columnName] = i + 1.1;
          if (columnType === 'date') obj[columnName] = new Date().getTime();
        });

        return obj;
      });
      setTableData(arr);
      return;
    }

    setTableData(_tableData);
    setInitialData('');
    setSort(null);
    setSearch('');
    socket.current = new WebSocket(TABLE_SOCKET_URL);
    socket.current.onopen = () => getData({ page: 1 });

    socket.current.onmessage = ({ data }) => {
      const tableInfo = JSON.parse(data);

      if (!data || tableInfo?.data === null) {
        setTableData([]);
        setInitialData('');
        return;
      }

      try {
        const list = tableInfo?.data.map((v: ListItem) => ({
          ...v,
          actions: { isEdit: false, isRemoved: false },
        }));
        setTableData(list);
        setInitialData(JSON.stringify(tableInfo.data));
      } catch (e) {
        setError('Ошибка обработки данных');
      }
    };
  }, [tableSettings, user]);

  const getData = ({
    page,
    newSort,
    newSearch,
  }: {
    page?: number;
    newSort?: SortItem;
    newSearch?: string;
  }) => {
    const access = TokenService.getAccessToken();
    const ws = socket.current;

    if (!access || !ws || ws.readyState !== WebSocket.OPEN) {
      return;
    }

    const currentSort = newSort || sort;
    const currentSearch = typeof newSearch === 'string' ? newSearch : search;
    const sortString = currentSort
      ? `&sortField=${currentSort.name}&sortDirection=${currentSort.sort}`
      : '';
    const searchString = `&search=${currentSearch}`;

    ws.send(
      `page=${page}&limit=100000&tableID=${tableSettings?.id}&tokenString=${access}${sortString}${searchString}`,
    );
  };

  const resetTableData = () => {
    const list = JSON.parse(initialData).map((v: ListItem) => ({
      ...v,
      actions: { isEdit: false, isRemoved: false },
    }));
    setTableData(list);
  };

  const addEmptyRows = (count: number) => {
    const newRows = Array.from({ length: count }).map(() => createEmptyRow(tableSettings));

    setTableData(prev => [...prev, ...newRows]);
  };

  const handleSetSort = (newSort: SortItem) => {
    setSort(newSort);
    getData({ page: 1, newSort });
  };

  const handleSetSearch = (newSearch: string) => {
    setSearch(newSearch);
    getData({ page: 1, newSearch });
  };

  const saveRows = async () => {
    if (!tableSettings?.id) {
      return;
    }

    setIsSaveLoading(true);

    const clearlyData = tableData.map(el => {
      const temp = { ...el };
      delete temp?.['actions'];
      delete temp?.['isDateOpen'];
      delete temp?.['isOpen'];

      return temp;
    });

    if (JSON.stringify(clearlyData) === initialData) {
      setIsSaveLoading(false);
      return;
    }

    const params = createRowsBatchData({ initialData, clearlyData });

    try {
      const { data } = await TableService.batchRows({ data: params, table_id: tableSettings.id });

      parseRowsBatchDataRes({ data, setTableData, setInitialData, initialData });
    } catch (e) {
      setError('Ошбика сохранения данных');
    } finally {
      setIsSaveLoading(false);
    }
  };

  return (
    <TableContext.Provider
      value={{
        isSaveLoading,
        setIsSaveLoading,
        tableData,
        setTableData,
        resetTableData,
        addEmptyRows,
        saveRows,
        setSort: handleSetSort,
        setSearch: handleSetSearch,
      }}
    >
      {children}
    </TableContext.Provider>
  );
};
