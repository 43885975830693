import React, { FC, ReactNode } from 'react';
import { ProjectsProvider } from 'src/app/providers/ProjectProvider';
import { TableProvider } from 'src/app/providers/TableProvider';
import { UserProvider } from 'src/app/providers/UserProvider';

interface ProvidersContainerProps {
  children?: ReactNode;
}

export const ProvidersContainer: FC<ProvidersContainerProps> = ({ children }) => {
  return (
    <UserProvider>
      <ProjectsProvider>
        <TableProvider>{children}</TableProvider>
      </ProjectsProvider>
    </UserProvider>
  );
};
