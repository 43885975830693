import { ReactNode } from 'react';
import { Cell, CellTemplate, Compatible, Uncertain, UncertainCompatible } from '@silevis/reactgrid';

export interface MyFileCell extends Cell {
  type: 'file';
  file?: string;
}

export class FileCellTemplate implements CellTemplate<MyFileCell> {
  getCompatibleCell(uncertainCell: Uncertain<MyFileCell>): Compatible<MyFileCell> {
    const text = uncertainCell.file || '';

    const value = parseFloat(text);
    return { ...uncertainCell, text, value };
  }

  update(
    cell: Compatible<MyFileCell>,
    cellToMerge: UncertainCompatible<MyFileCell>,
  ): Compatible<MyFileCell> {
    return {
      ...cell,
      file: cellToMerge.file,
    };
  }

  getClassName(cell: Compatible<MyFileCell>, isInEditMode: boolean): string {
    return cell.className ? cell.className : '';
  }

  render(
    cell: Compatible<MyFileCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<MyFileCell>, commit: boolean) => void,
  ): ReactNode {
    return <>{cell.file}</>;
  }
}
