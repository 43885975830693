import { FC, ReactNode, useContext, useEffect } from 'react';
import { ReactNotifications } from 'react-notifications-component';
import { useLocation, useNavigate } from 'react-router';
import AuthService from 'src/shared/api/auth/AuthService';
import TokenService from 'src/shared/api/token/TokenService';
import { routesEnum } from 'src/shared/const';
import { UserContext } from 'src/shared/store/user';

import 'react-notifications-component/dist/theme.css';

interface MainContainerProps {
  children?: ReactNode;
}

export const MainContainer: FC<MainContainerProps> = ({ children }) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { user, setUser, isUserLoading, setIsUserLoading } = useContext(UserContext);

  useEffect(() => {
    (async () => {
      const tokens = TokenService.getTokens();

      if (!tokens) {
        return setIsUserLoading(false);
      }

      try {
        const { data } = await AuthService.getUser();

        setUser(data.user);
      } catch (e) {
      } finally {
        setIsUserLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (isUserLoading) {
      return;
    }

    const tokens = TokenService.getTokens();

    if (!user || !tokens) {
      return navigate(routesEnum.LOGIN);
    }

    const isExistRoute = (Object.values(routesEnum) as string[]).includes(pathname);
    const isPublicRoute = routesEnum.LOGIN === pathname;

    if (isExistRoute || isPublicRoute) {
      navigate(routesEnum.TABLE);
    }
  }, [user, isUserLoading]);

  return (
    <div className="h-dvh w-dvw bg-blue-200">
      <ReactNotifications />
      {children}
    </div>
  );
};
