import React, { FC, useState } from 'react';
import { createPortal } from 'react-dom';
import { ProjectSettingDirectoryForm } from 'src/entity/ProjectSettingDirectoryForm';
import { LocalFieldType } from 'src/entity/ProjectSettingsForm';
import { ProjectSettingsForm } from 'src/entity/ProjectSettingsForm/ui';
import { FormDirectoryType } from 'src/shared/store/projects/types';
import { Modal } from 'src/shared/ui';

interface CreateFormModalProps {
  onClose: () => void;
}

export const CreateFormModal: FC<CreateFormModalProps> = ({ onClose }) => {
  const [directoryModal, setDirectoryModal] = useState<number | null>();
  const [localFields, setLocalFields] = useState<LocalFieldType[]>([]);

  const addDirectory = (i: number, data: FormDirectoryType) => {
    setDirectoryModal(null);
    setLocalFields(prev =>
      prev.map((el, index) => ({ ...el, ...(i === index && { dictionary: data }) })),
    );
  };

  return (
    <>
      <Modal
        onClose={() => {
          if (directoryModal) return;
          onClose();
        }}
      >
        <div className="flex w-[50rem] flex-col items-center">
          <div className="font-gothampro-500 mb-5 w-fit text-xl text-green-100">Новый проект</div>
          <div className="font-gothampro-400 mb-5 w-fit text-sm text-white">
            Чтобы создать новый проект заполните поля
          </div>

          <ProjectSettingsForm
            setDirectoryModal={setDirectoryModal}
            localFields={localFields}
            setLocalFields={setLocalFields}
            onClose={onClose}
          />
        </div>
      </Modal>

      {directoryModal &&
        createPortal(
          <Modal
            classNames="overflow- z-150"
            bgClassNames="z-150"
            onClose={() => {
              setDirectoryModal(null);
            }}
          >
            <ProjectSettingDirectoryForm
              onSave={addDirectory}
              index={directoryModal - 1}
              directory={localFields[directoryModal - 1]?.dictionary}
              onClose={() => setDirectoryModal(null)}
            />
          </Modal>,
          document.body,
        )}
    </>
  );
};
