import { Dispatch, SetStateAction } from 'react';
import { CellChange, CellStyle, Column, NumberCell, Row, TextCell } from '@silevis/reactgrid';
import { MyActionsCell } from 'src/entity/CellTemplates/ActionsCellTemplate';
import { MyDateCell } from 'src/entity/CellTemplates/DateCellTemplate';
import { DropdownCell } from 'src/entity/CellTemplates/DropDownCellTemplate';
import { MyHeaderCell } from 'src/entity/CellTemplates/HeaderCellComponent';
import { FieldType } from 'src/entity/ProjectSettingsForm/types';
import { TableSettingsForm } from 'src/shared/store/projects/types';
import { ListItem } from 'src/shared/store/table';
import { SortItem } from 'src/shared/store/table/types';

import { cellHeight, headerHeight } from './constants';

export const getStyle = (props: CellStyle): CellStyle => {
  const border = '#001932';

  return {
    border: {
      right: { color: border },
      top: { color: border },
      left: { color: border },
      bottom: { color: border },
    },
    ...props,
  };
};

export const getColumns = (header: FieldType[]): Column[] =>
  header.map(({ columnName, columnType }) => ({
    columnId: columnName,
    width: columnName.toLowerCase() === 'id' ? 40 : columnType === 'actions' ? 88 : undefined,
    resizable: !(columnName.toLowerCase() === 'id' || columnType === 'actions'),
  }));

export const getHeaderRow = (tableSettings: TableSettingsForm): Row<MyHeaderCell> => ({
  rowId: 'header',
  cells:
    tableSettings?.formStructure.map(({ columnName, columnLabel, columnType }) => ({
      type: 'header',
      text: columnName.toLowerCase() === 'id' ? 'ID' : columnLabel,
      style: getStyle({ background: columnType === 'actions' ? '#001932' : '#2A4560' }),
      enableSort: columnName.toLowerCase() !== 'id' && columnType !== 'actions',
      enableFilter: columnName.toLowerCase() !== 'id' && columnType !== 'actions',
      enableActions: columnType === 'actions',
    })) || [],
  height: headerHeight,
});

export const getRows = (
  list: ListItem[],
  headerRow: Row<MyHeaderCell>,
  header: FieldType[],
): Row[] => [
  headerRow,
  ...list.map<Row<any>>((item, i) => ({
    rowId: i,
    cells: header.map(head => {
      const type = head.columnType;
      const name = head.columnName;
      const value = item[head.columnName];
      const isEdit = !!item?.actions?.['isEdit'] || item?.actions?.['newId'];

      const cell = {
        type,
        style: getStyle({
          background: type === 'actions' ? '#001932' : '#0D2F4D',
        }),
        nonEditable: type !== 'actions',
      };

      if (type === 'actions') {
        return { ...cell, isEdit, newId: item?.actions?.['newId'] };
      }

      cell.nonEditable = !isEdit;

      if (type === 'number' || type === 'floatNumber') {
        return { ...cell, value, ...(name.toLowerCase() === 'id' && { nonEditable: true }) };
      }

      if (type === 'date') {
        return {
          ...cell,
          date: value,
          dateOpen: item?.dateOpen,
          dateKey: head?.columnName,
        };
      }

      if (type === 'dropdown') {
        return {
          ...cell,
          selectedValue: value?.toString() || '',
          values:
            head?.data?.map(el => ({
              label: el?.value,
              value: el?.key?.toString(),
            })) || [],
          selectOpen: item?.selectOpen,
          selectKey: head?.columnName,
          styles: { indicatorSeparator: { display: 'none' } },
        };
      }
      return { ...cell, text: value || '' };
    }),
    height: cellHeight,
  })),
];

interface MyFloatNumberCell extends Omit<NumberCell, 'type'> {
  type: 'floatNumber';
}

export const applyChanges = (
  changes: CellChange<
    | TextCell
    | MyDateCell
    | DropdownCell
    | MyHeaderCell
    | MyActionsCell
    | NumberCell
    | MyFloatNumberCell
  >[],
  prev: ListItem[],
  setSort: (sort: SortItem) => void,
  setHeaderRows: Dispatch<SetStateAction<Row<MyHeaderCell>>>,
): ListItem[] => {
  changes.forEach(change => {
    const { rowId, columnId } = change;
    const type = change.newCell.type;
    const newId = prev?.[rowId]?.['actions']?.['newId'];

    if (type === 'actions') {
      prev[rowId].actions.isEdit = change.newCell.isEdit;

      if (change.newCell.isRemoved) {
        if (newId) {
          prev = prev.filter(el => el.actions?.['newId'] !== newId);
        } else {
          prev = prev.filter(el => el.id !== prev?.[rowId]?.id);
        }
      }
    }

    if (type === 'header') {
      setHeaderRows(temp => {
        const sort = change.newCell?.['sort'];
        temp.cells.forEach((el, i) => {
          if (sort) {
            const isActive = el?.['text'] === change.newCell?.['text'];

            if (isActive) {
              setSort({ name: columnId.toString(), sort });
            }
            temp.cells[i].sort = isActive ? sort : undefined;
          }
        });

        return { ...temp };
      });
    }

    if (type === 'text') {
      prev[rowId][columnId] = change.newCell.text;
    }

    if (type === 'number' || type === 'floatNumber') {
      prev[rowId][columnId] = change.newCell.value;
    }

    if (type === 'date') {
      if (change.newCell.date !== change.previousCell?.['date']) {
        prev[rowId][columnId] = change.newCell.date;
      }
      prev[rowId].dateOpen = change.newCell.dateOpen;
    }

    if (type === 'dropdown') {
      if (
        change.newCell.selectedValue &&
        change.newCell.selectedValue !== change.previousCell?.['selectedValue']
      ) {
        prev[rowId][columnId] = change.newCell.selectedValue;
      }
      prev[rowId].selectOpen = change.newCell.selectOpen;
    }
  });
  return [...prev];
};
