const getNavigatorLanguage = (): string => navigator.language || 'en-US';

const getLocaleSeparators = (locale: string) => {
  const testNumber = 123456.789;
  const localeFormattedNumber = Intl.NumberFormat(locale).format(testNumber);

  // Get the thousands separator of the locale
  const thousandsSeparator = localeFormattedNumber.split('123')[1][0];

  // Get the decimal separator of the locale
  const decimalSeparator = localeFormattedNumber.split('123')[1][4];
  return { thousandsSeparator, decimalSeparator };
};

export const parseLocaleNumber = (
  stringNumber: string,
  locale = getNavigatorLanguage(),
): number => {
  if (!stringNumber.trim()) return NaN;
  const { thousandsSeparator, decimalSeparator } = getLocaleSeparators(locale);
  const normalizedStringNumber = stringNumber.replace(/\u00A0/g, ' ');
  const numberString = normalizedStringNumber
    .replace(new RegExp(`[${thousandsSeparator}\\s]`, 'g'), '')
    .replace(new RegExp(`\\${decimalSeparator}`, 'g'), '.');

  const trimmedNumberString = numberString.replace(/^(?!-)\D+|\D+$/g, '');
  if (trimmedNumberString === null || trimmedNumberString.trim().length === 0) {
    return NaN;
  }
  return Number(trimmedNumberString);
};
