import { FC } from 'react';
import { LoginForm } from 'src/widgets/LoginForm';

export const LoginPage: FC = () => {
  return (
    <div className="flex h-full w-full items-center justify-center">
      <LoginForm />
    </div>
  );
};
