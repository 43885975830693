import { FC, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { sleep } from 'src/shared/lib/utils';
import { DatePicker } from 'src/shared/ui/DatePicker';

interface TableDatePickerProps {
  markedDate: Date;
  onClose: () => void;
  onClick: (date: Date) => void;
}

export const TableDatePicker: FC<TableDatePickerProps> = ({ markedDate, onClose, onClick }) => {
  const [coords, setCoords] = useState<{ left: number; top: number }>();

  useEffect(() => {
    (async () => {
      await sleep(50);

      const element = document.querySelector('#date-picker');
      const rect = element?.getBoundingClientRect();

      if (rect) {
        const left = rect.left - 280;
        let top = rect.top;

        if (top > document.documentElement.clientHeight / 2) {
          top = top - 322;
        } else {
          top = top + 44;
        }

        setCoords({ left, top });
      }
    })();
  }, []);

  return createPortal(
    <div
      className="fixed z-10 w-80"
      style={{
        ...coords,
      }}
    >
      <DatePicker markedDate={markedDate} onClose={onClose} onClick={onClick} />
    </div>,
    document.body,
  );
};
