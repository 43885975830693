import React, { FC, useContext, useState } from 'react';
import { CellChange, CellLocation, ReactGrid, Row, TextCell } from '@silevis/reactgrid';
import { TableContext } from 'src/app/providers/TableProvider';
import {
  ActionsCellTemplate,
  DateCellTemplate,
  DropdownCellTemplate,
  FileCellTemplate,
  HeaderCellTemplate,
  MyHeaderCell,
  NumberCellTemplate,
} from 'src/entity/CellTemplates';
import { TableSettingsForm } from 'src/shared/store/projects/types';
import { UserContext } from 'src/shared/store/user';
import { CellTemplates } from 'src/shared/types';
import { Loader } from 'src/shared/ui/Loader';

import { useCheckAddRow, useCheckPasteAddRow, useColumns } from '../hooks';
import { applyChanges, getHeaderRow, getRows } from '../utils';

import '@silevis/reactgrid/styles.css';
import '../styles/styles.css';

interface TableProps {
  isOpenedProjectList: boolean;
  tableSettings: TableSettingsForm;
  height: string;
}

export const Table: FC<TableProps> = ({ isOpenedProjectList, height, tableSettings }) => {
  const { tableData, setTableData, setSort, isSaveLoading } = useContext(TableContext);
  const { user } = useContext(UserContext);

  const [headerRows, setHeaderRows] = useState<Row<MyHeaderCell>>(getHeaderRow(tableSettings));

  const isDeveloper = user?.isDeveloper;

  useCheckAddRow();
  const { setSelectedRowId } = useCheckPasteAddRow();
  const { columns, handleColumnResize } = useColumns(isOpenedProjectList, tableSettings);

  const handleChanges = (changes: CellChange<TextCell>[]) => {
    setTableData(applyChanges(changes, tableData, setSort, setHeaderRows));
  };

  const handleFocusLocationChanged = (location: CellLocation) => {
    setSelectedRowId(location.rowId);
  };

  const myCellTemplates: CellTemplates = {
    date: new DateCellTemplate(),
    header: new HeaderCellTemplate(),
    file: new FileCellTemplate(),
    actions: new ActionsCellTemplate(),
    floatNumber: new NumberCellTemplate(),
    dropdown: new DropdownCellTemplate(),
    number: new NumberCellTemplate(),
  };

  return (
    <div
      id="table-container"
      className="grid-table relative max-w-full overflow-x-auto overflow-y-auto"
      style={{ height }}
    >
      {isSaveLoading && (
        <div className="absolute inset-0 z-10 flex items-center justify-center bg-blue-rgba-100">
          <Loader classNames="w-32 h-32" />
        </div>
      )}

      <ReactGrid
        rows={getRows(tableData, headerRows, tableSettings?.formStructure || [])}
        columns={columns}
        onColumnResized={handleColumnResize}
        onCellsChanged={handleChanges}
        onFocusLocationChanged={handleFocusLocationChanged}
        customCellTemplates={myCellTemplates}
        stickyTopRows={1}
        stickyRightColumns={!isDeveloper ? 1 : 0}
        enableFillHandle={!isDeveloper}
        enableRangeSelection={!isDeveloper}
      />
    </div>
  );
};
