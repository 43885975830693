import { FC, ReactNode } from 'react';
import { Header } from 'src/widgets/Header/ui';

interface ProtectedContainerProps {
  children?: ReactNode;
}

export const ProtectedContainer: FC<ProtectedContainerProps> = ({ children }) => {
  return (
    <div className="flex h-full w-full flex-col px-14 pb-8">
      <Header />
      <div className="w-full flex-1">{children}</div>
    </div>
  );
};
