import { ReactNode } from 'react';
import { Cell, CellTemplate, Compatible, Uncertain, UncertainCompatible } from '@silevis/reactgrid';
import cn from 'classnames';
import { ReactComponent as EditIcon } from 'src/shared/assets/icons/kit/edit.svg';
import { ReactComponent as TrashIcon } from 'src/shared/assets/icons/kit/trash.svg';
import { Button } from 'src/shared/ui';

export interface MyActionsCell extends Cell {
  type: 'actions';
  isRemoved: boolean;
  isEdit: boolean;
  newId?: string;
}

export class ActionsCellTemplate implements CellTemplate<MyActionsCell> {
  getCompatibleCell(uncertainCell: Uncertain<MyActionsCell>): Compatible<MyActionsCell> {
    const isRemoved = !!uncertainCell.isRemoved;
    const isEdit = !!uncertainCell.isEdit;
    const newId = uncertainCell.newId;

    return { ...uncertainCell, isEdit, isRemoved, newId, text: '1', value: 1 };
  }

  update(
    cell: Compatible<MyActionsCell>,
    cellToMerge: UncertainCompatible<MyActionsCell>,
  ): Compatible<MyActionsCell> {
    return {
      ...cell,
      isRemoved: !!cellToMerge.isRemoved,
      isEdit: !!cellToMerge.isEdit,
    };
  }

  isFocusable() {
    return false;
  }

  getClassName(cell: Compatible<MyActionsCell>, isInEditMode: boolean): string {
    return cell.className ? cell.className : '';
  }

  render(
    cell: Compatible<MyActionsCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<MyActionsCell>, commit: boolean) => void,
  ): ReactNode {
    const { isEdit, newId } = cell;

    return (
      <div className="flex gap-1">
        <Button
          variable="icon"
          variant="grey"
          size="small"
          tooltip="Удалить"
          onClick={() => onCellChanged({ ...cell, isRemoved: true }, true)}
        >
          <TrashIcon className="h-4 w-4 stroke-grey-300" />
        </Button>

        {!newId && (
          <Button
            variable="icon"
            variant={isEdit ? 'lightBlue' : 'grey'}
            size="small"
            tooltip="Редактировать"
            onClick={() => onCellChanged({ ...cell, isEdit: !isEdit }, true)}
          >
            <EditIcon
              className={cn(
                {
                  'fill-blue-400': isEdit,
                  'fill-grey-300': !isEdit,
                },
                'h-4 w-4',
              )}
            />
          </Button>
        )}
      </div>
    );
  }
}
