import { FC, useContext } from 'react';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import { TableContext } from 'src/app/providers/TableProvider';
import AuthService from 'src/shared/api/auth/AuthService';
import TokenService from 'src/shared/api/token/TokenService';
import { ReactComponent as ArrowIcon } from 'src/shared/assets/icons/kit/arrow.svg';
import { ReactComponent as UserIcon } from 'src/shared/assets/icons/kit/user.svg';
import logo from 'src/shared/assets/images/logo.png';
import { projectsContextDefaultState } from 'src/shared/store/projects/constants';
import { tableContextDefaultState } from 'src/shared/store/table/constants';
import { UserContext } from 'src/shared/store/user';

export const Header: FC = () => {
  const { user, setUser } = useContext(UserContext);
  const { setForms, setSelectedForm, setSelectedProject, setTableSettings, setProjects } =
    useContext(ProjectsContext);
  const { setTableData } = useContext(TableContext);

  const handleLogout = async () => {
    try {
      await AuthService.logout();
    } catch (e) {
    } finally {
      const { forms, selectedForm, selectedProject, tableSettings, projects } =
        projectsContextDefaultState;
      setForms(forms);
      setSelectedForm(selectedForm);
      setSelectedProject(selectedProject);
      setTableSettings(tableSettings);
      setProjects(projects);

      setTableData(tableContextDefaultState.tableData);

      TokenService.removeTokens();
      setUser(null);
    }
  };

  return (
    <div className="flex h-20 w-full items-center">
      <div className="h-6 w-16">
        <img className="h-full w-full object-contain" src={logo} alt="" />
      </div>

      <div className="ml-auto mr-8 flex h-full items-center">
        <div className="mr-2.5 flex h-11 w-11 items-center justify-center rounded-full bg-orange-100">
          <UserIcon className="h-4 w-4 fill-white" />
        </div>
        <div className="font-gothampro-400 text-sm text-white">{user?.email || 'Login'}</div>
      </div>

      <button className="group flex items-center" onClick={handleLogout}>
        <div className="font-gothampro-500 mr-2.5 text-grey-400 transition-all group-hover:text-grey-300">
          Выход
        </div>
        <ArrowIcon className="h-4 w-4 fill-grey-400 transition-all group-hover:fill-grey-300" />
      </button>
    </div>
  );
};
