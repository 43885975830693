import { FC, memo, useContext, useMemo, useState } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import { ProjectSettingDirectoryForm } from 'src/entity/ProjectSettingDirectoryForm';
import { FieldVariantsType, LocalFieldType } from 'src/entity/ProjectSettingsForm/types';
import { ProjectSettingsForm } from 'src/entity/ProjectSettingsForm/ui';
import { ReactComponent as ArrowSmallIcon } from 'src/shared/assets/icons/kit/arrow-small.svg';
import { FormDirectoryType } from 'src/shared/store/projects/types';
import { StateActionType } from 'src/shared/types';
import { DarkBackground, Modal } from 'src/shared/ui';

interface ProjectSettingsProps {
  isOpenedProjectSettings: boolean;
  setIsOpenProjectSettings: StateActionType<boolean>;
  classNames?: string;
}

export const ProjectSettings: FC<ProjectSettingsProps> = memo(
  ({ isOpenedProjectSettings, setIsOpenProjectSettings, classNames }) => {
    const [directoryModal, setDirectoryModal] = useState<number | null>();
    const [localFields, setLocalFields] = useState<LocalFieldType[]>([]);

    const handleOpenProjectSettings = () => setIsOpenProjectSettings(prev => !prev);

    const { tableSettings } = useContext(ProjectsContext);

    const addDirectory = (i: number, data: FormDirectoryType) => {
      setDirectoryModal(null);
      setLocalFields(prev =>
        prev.map((el, index) => ({ ...el, ...(i === index && { dictionary: data }) })),
      );
    };

    const settings = useMemo(
      () =>
        tableSettings
          ? {
              ...tableSettings,
              formStructure: tableSettings?.formStructure
                .filter(el => el.columnType !== 'actions' && el.columnName.toLowerCase() !== 'id')
                .map(el => ({
                  ...el,
                  columnType: el.columnType.replace('dropdown', 'reference') as FieldVariantsType,
                })),
            }
          : undefined,
      [tableSettings],
    );

    return (
      <>
        {isOpenedProjectSettings && <DarkBackground />}
        <div
          className={cn(
            {
              'z-100 h-14 w-72 overflow-hidden': !isOpenedProjectSettings,
              'z-120 h-full w-[54rem] drop-shadow-black-100': isOpenedProjectSettings,
            },
            'flex flex-col rounded bg-blue-600',
            classNames,
          )}
        >
          <div
            className="flex h-14 flex-shrink-0 cursor-pointer items-center px-4"
            onClick={handleOpenProjectSettings}
          >
            <ArrowSmallIcon
              className={cn(
                {
                  '-rotate-90': isOpenedProjectSettings,
                  'rotate-180': !isOpenedProjectSettings,
                },
                'h-3 w-3 stroke-white',
              )}
            />
            <div
              className={cn(
                {
                  'ml-auto': !isOpenedProjectSettings,
                  'ml-4': isOpenedProjectSettings,
                },
                'font-gothampro-400 text-lg text-white',
              )}
            >
              Настройки проекта
            </div>
          </div>

          {isOpenedProjectSettings && tableSettings && (
            <ProjectSettingsForm
              setDirectoryModal={setDirectoryModal}
              setLocalFields={setLocalFields}
              localFields={localFields}
              settings={settings}
              onClose={handleOpenProjectSettings}
            />
          )}
        </div>

        {directoryModal &&
          createPortal(
            <Modal classNames="overflow-" onClose={() => setDirectoryModal(null)}>
              <ProjectSettingDirectoryForm
                onSave={addDirectory}
                index={directoryModal - 1}
                directory={localFields[directoryModal - 1]?.dictionary}
                onClose={() => setDirectoryModal(null)}
              />
            </Modal>,
            document.body,
          )}
      </>
    );
  },
);
