import React, { FC, useContext, useMemo, useState } from 'react';
import cn from 'classnames';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import { TableContext } from 'src/app/providers/TableProvider';
import { CreateFormModal } from 'src/feature/CreateFormModal';
import ProjectsService from 'src/shared/api/projects/ProjectsService';
import { FormType, TableSettingsForm } from 'src/shared/store/projects/types';
import { UserContext } from 'src/shared/store/user';
import { Button, Input, ToolTip } from 'src/shared/ui';
import { Loader } from 'src/shared/ui/Loader';

interface FormsListProps {
  isSelected: boolean;
}

export const FormsList: FC<FormsListProps> = ({ isSelected }) => {
  const {
    forms,
    isFormsLoading,
    selectedForm,
    setSelectedForm,
    setIsTableSettingsLoading,
    setTableSettings,
  } = useContext(ProjectsContext);
  const { setTableData } = useContext(TableContext);
  const { user } = useContext(UserContext);
  const [isOpenFormModal, setIsOpenFormModal] = useState<boolean>(false);
  const [search, setSearch] = useState<string>('');

  const handleOpenFormModal = () => setIsOpenFormModal(true);
  const handleCloseFormModal = () => setIsOpenFormModal(false);

  const handleSelectForm = async (form: FormType) => {
    if (selectedForm?.id === form.id) {
      return;
    }

    setSelectedForm(form);
    setIsTableSettingsLoading(true);
    setTableSettings(null);
    setTableData([]);

    try {
      const { data } = await ProjectsService.getTableSettings({ formId: form.id });

      if (!data) {
        return;
      }
      const { Id, Alias, FormName, FormDescription, FormStructure } = data;

      const tableSettings: TableSettingsForm = {
        id: Id || data?.['ID'],
        formDescription: FormDescription,
        alias: Alias,
        formName: Alias,
        connection: FormName,
        formStructure: FormStructure,
      };

      setTableSettings(tableSettings);
    } catch (e) {
    } finally {
      setIsTableSettingsLoading(false);
    }
  };

  const filteredForms = useMemo(() => {
    if (!search) {
      return forms;
    }

    return forms.filter(({ form_name }) => form_name.toLowerCase().includes(search.toLowerCase()));
  }, [forms, search]);

  return (
    <>
      {isSelected && isFormsLoading && (
        <div className="flex h-28 w-full items-center justify-center border-b border-blue-400">
          <Loader classNames="w-10 h-10" />
        </div>
      )}

      {isSelected && !isFormsLoading && (
        <div className="w-full">
          {!forms?.length && (
            <div
              className={
                'flex h-14 min-h-14 w-full items-center bg-blue-rgba-100 ' +
                'justify-center border-b border-blue-400'
              }
            >
              <div className="font-gothampro-400 tex-sm text-white">Формы не найдены</div>
            </div>
          )}

          {forms?.length ? (
            <div className="w-full flex-shrink-0 border-t border-blue-300 bg-blue-rgba-100 px-4 py-2.5">
              <Input placeholder="Поиск" onChange={e => setSearch(e.target.value)} />
            </div>
          ) : null}

          {filteredForms.map(form => (
            <ToolTip key={`form-${form.id}`} label={form.form_description}>
              <div
                className={cn(
                  {
                    'bg-blue-rgba-200': form.id === selectedForm?.id,
                    'hover:bg-blue-rgba-200': form.id !== selectedForm?.id,
                  },
                  'flex h-14 min-h-14 w-full cursor-pointer items-center bg-blue-rgba-100 ' +
                    'border-b border-blue-400 pl-10 pr-4',
                )}
                onClick={() => handleSelectForm(form)}
              >
                <div className="font-gothampro-400 tex-sm truncate text-white">
                  {form.form_name}
                </div>
              </div>
            </ToolTip>
          ))}

          {!user?.isOperator && (
            <div
              className={
                'flex h-14 min-h-14 w-full cursor-pointer items-center bg-blue-rgba-100 ' +
                'border-b border-blue-400 px-12'
              }
              onClick={handleOpenFormModal}
            >
              <Button size="small">Добавить форму</Button>
            </div>
          )}
        </div>
      )}

      {isOpenFormModal && <CreateFormModal onClose={handleCloseFormModal} />}
    </>
  );
};
