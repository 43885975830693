import React, { FC, useContext, useState } from 'react';
import cn from 'classnames';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import { FormsList } from 'src/feature/FormsList/ui';
import ProjectsService from 'src/shared/api/projects/ProjectsService';
import { ReactComponent as ArrowSmallIcon } from 'src/shared/assets/icons/kit/arrow-small.svg';
import { ProjectType } from 'src/shared/store/projects/types';
import { ToolTip } from 'src/shared/ui';

interface ProjectListItemProps {
  project: ProjectType;
}

export const ProjectListItem: FC<ProjectListItemProps> = ({ project }) => {
  const { id, project_name, project_description } = project;
  const { setForms, setIsFormsLoading, selectedProject, setSelectedProject } =
    useContext(ProjectsContext);

  const [isOpen, setIsOpen] = useState(false);

  const isSelected = id === selectedProject?.id && isOpen;

  const handleSelectProject = async () => {
    if (isSelected) {
      setIsOpen(false);
      return;
    }

    setIsOpen(true);
    setSelectedProject(project);
    setIsFormsLoading(true);

    try {
      const { data } = await ProjectsService.getFormsByProjectId({ projectId: project.id });

      setForms(data || []);
    } catch (e) {
    } finally {
      setIsFormsLoading(false);
    }
  };

  return (
    <div>
      <ToolTip label={project_description}>
        <div
          className={cn(
            {
              'bg-blue-400': id === selectedProject?.id,
              'hover:bg-blue-400': id !== selectedProject?.id,
            },
            'flex min-h-14 w-full cursor-pointer items-center border-b border-blue-400 px-4 transition-all',
          )}
          onClick={handleSelectProject}
        >
          <div className="font-gothampro-400 w-full truncate text-white">{project_name}</div>
          <button className="ml-auto h-5 w-5">
            <ArrowSmallIcon
              className={cn(
                { 'rotate-90': isSelected, '-rotate-90': !isSelected },
                'h-3 w-3 stroke-white',
              )}
            />
          </button>
        </div>
      </ToolTip>

      <FormsList isSelected={isSelected} />
    </div>
  );
};
