import { LoginPage } from 'src/pages/LoginPage';
import { TablePage } from 'src/pages/TablePage';
import { routesEnum } from 'src/shared/const';
import { ProtectedRoute, PublicRoute } from 'src/shared/types';

export const publicRoutes: PublicRoute[] = [
  {
    path: routesEnum.LOGIN,
    element: <LoginPage />,
  },
];

export const protectedRoutes: ProtectedRoute[] = [
  {
    path: routesEnum.TABLE,
    element: <TablePage />,
  },
];
