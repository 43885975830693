import { useContext, useEffect, useState } from 'react';
import { Column, Id } from '@silevis/reactgrid';
import { UserContext } from 'src/app/providers/UserProvider';
import { TableSettingsForm } from 'src/shared/store/projects/types';

import { columnWidthLocal } from '../constants';
import { getColumns } from '../utils';

export const useColumns = (isOpenedProjectList: boolean, tableSettings: TableSettingsForm) => {
  const [columns, setColumns] = useState<Column[]>(getColumns(tableSettings?.formStructure || []));

  const { user } = useContext(UserContext);

  const getColumnWidthsFromLocal = () => {
    const columnWidthsJSON = localStorage.getItem(columnWidthLocal);

    return columnWidthsJSON ? JSON.parse(columnWidthsJSON) : null;
  };

  const setColumnWidthToLocal = (obj: Object) => {
    localStorage.setItem(columnWidthLocal, JSON.stringify(obj));
  };

  const getColumnWidth = (column: Column, width: number) => {
    if (column.columnId === 'actions' || column.columnId === 'id') {
      return column.width || width;
    }

    const columnWidths = getColumnWidthsFromLocal();
    const columnWidth = columnWidths?.[tableSettings.id]?.[column.columnId];

    if (columnWidth) {
      return columnWidth;
    }

    return width;
  };

  useEffect(() => {
    const table = document.querySelector('#table-container') as HTMLDivElement;

    if (!table) {
      return;
    }

    const offsetWidth = table.offsetWidth - (user?.isDeveloper ? 44 : 128);
    const colWidth =
      offsetWidth / (tableSettings.formStructure.length - (user?.isDeveloper ? 1 : 2));
    const width = colWidth < 200 ? 200 : colWidth;

    setColumns(prev =>
      prev.map(col => ({
        ...col,
        width: getColumnWidth(col, width),
      })),
    );
  }, [tableSettings, user, isOpenedProjectList]);

  const handleColumnResize = (ci: Id, width: number) => {
    setColumns(prevColumns => {
      const columnIndex = prevColumns.findIndex(el => el.columnId === ci);
      const resizedColumn = prevColumns[columnIndex];
      prevColumns[columnIndex] = { ...resizedColumn, width };
      return [...prevColumns];
    });

    const columnWidths = getColumnWidthsFromLocal();

    if (!columnWidths) {
      setColumnWidthToLocal({ [tableSettings.id]: { [ci]: width } });
    } else {
      setColumnWidthToLocal({
        ...columnWidths,
        [tableSettings.id]: { ...columnWidths?.[tableSettings.id], [ci]: width },
      });
    }
  };

  return { columns, handleColumnResize };
};
