import { FC, ReactNode } from 'react';
import cn from 'classnames';

interface DarkBackgroundProps {
  children?: ReactNode;
  classNames?: string;
}

export const DarkBackground: FC<DarkBackgroundProps> = ({ children, classNames }) => {
  return (
    <>
      <div
        className={cn(
          { 'z-110': !Boolean(classNames?.includes('z-')) },
          'fixed inset-0 h-dvh w-dvw bg-blue-1000 opacity-60',
          classNames,
        )}
      />
      {children}
    </>
  );
};
