import { FC, ReactNode, useEffect, useRef, useState } from 'react';
import cn from 'classnames';
import { ReactComponent as ArrowSmallIcon } from 'src/shared/assets/icons/kit/arrow-small.svg';
import { ReactComponent as ErrorIcon } from 'src/shared/assets/icons/kit/error.svg';
import { useClickOutside } from 'src/shared/lib/hooks/useClickOutside/index';
import { ToolTip } from 'src/shared/ui';

export type SelectItemType = {
  label: string;
  value: string;
  icon?: ReactNode;
};

interface SelectProps {
  onChange?: (value: SelectItemType) => void;
  options: SelectItemType[];
  error?: string;
  value?: string;
  disabled?: boolean;
  classNames?: string;
}

export const Select: FC<SelectProps> = ({
  classNames,
  disabled,
  error,
  options,
  onChange,
  value,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [selectValue, setSelectValue] = useState<SelectItemType | undefined>(
    options.find(option => option.value === value),
  );
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setSelectValue(options.find(option => option.value === value));
  }, [value]);

  useClickOutside(ref, () => {
    setIsOpen(false);
  });

  const handleOpen = () => {
    if (disabled) return;

    setIsOpen(prev => !prev);
  };

  const handleSelect = (item: SelectItemType) => {
    onChange && onChange(item);
    setSelectValue(item);
    setIsOpen(false);
  };

  return (
    <div
      className={cn(
        {
          'w-full': !classNames?.includes('w-'),
        },
        'relative h-11',
        classNames,
      )}
      ref={ref}
    >
      <div
        className={cn(
          {
            'rounded-b hover:border-blue-300': !isOpen,
            'border-blue-300 bg-blue-900': isOpen,
            'bg-grey-200': !selectValue && !isOpen,
            'bg-blue-500': selectValue && !isOpen,
          },
          'flex h-full w-full cursor-pointer items-center rounded-t border p-4',
          'border-blue-400 hover:bg-blue-900',
        )}
        onClick={handleOpen}
      >
        <ToolTip label={selectValue?.label}>
          <div className="font-gothampro-400 mr-auto truncate whitespace-nowrap text-white">
            {selectValue?.label}
          </div>
        </ToolTip>
        {selectValue?.icon && <div className="">{selectValue.icon}</div>}
        <ArrowSmallIcon
          className={cn({ '-rotate-90': isOpen }, 'ml-2 h-3 w-3 min-w-[0.75rem] stroke-white')}
        />
      </div>

      {isOpen && (
        <div
          className={
            'absolute left-0 top-full z-10 max-h-40 w-full overflow-auto rounded-b bg-blue-900 ' +
            'border-t border-blue-400 shadow-2xl'
          }
        >
          {options.map(option => (
            <div
              key={`option-${option.value}`}
              onClick={() => handleSelect(option)}
              className={cn(
                { 'bg-blue-500': selectValue?.value === option.value },
                'flex h-10 w-full cursor-pointer items-center border-t border-blue-400 px-4',
                'hover:bg-blue-500',
              )}
            >
              <ToolTip label={option.label}>
                <div className="font-gothampro-400 truncate whitespace-nowrap text-white">
                  {option.label}
                </div>
              </ToolTip>
              {option?.icon && (
                <div className="pointer-events-none ml-auto mr-2">{option.icon}</div>
              )}
            </div>
          ))}
        </div>
      )}
      {error && (
        <div className="absolute -bottom-4 flex">
          <ErrorIcon className="mr-1 h-2.5 w-2.5 fill-pink-100" />
          <div className="font-gothampro-400 text-extra-xs text-pink-100">{error}</div>
        </div>
      )}
    </div>
  );
};
