import React, { FC } from 'react';
import { CreateProjectForm } from 'src/entity/CreateProjectForm';
import { Modal } from 'src/shared/ui';

interface CreateProjectModalProps {
  onClose: () => void;
}

export const CreateProjectModal: FC<CreateProjectModalProps> = ({ onClose }) => {
  return (
    <Modal onClose={onClose}>
      <div className="flex w-full flex-col items-center">
        <div className="font-gothampro-500 mb-5 w-fit text-xl text-green-100">Новый проект</div>
        <div className="font-gothampro-400 mb-5 w-fit text-sm text-white">
          Чтобы создать новый проект заполните поля
        </div>

        <CreateProjectForm onClose={onClose} />
      </div>
    </Modal>
  );
};
