import { ReactNode } from 'react';
import {
  CellTemplate,
  Compatible,
  DateCell,
  getCharFromKey,
  isCharAlphaNumeric,
  keyCodes,
  Uncertain,
  UncertainCompatible,
} from '@silevis/reactgrid';
import cn from 'classnames';
import { TableDatePicker } from 'src/entity/CellTemplates/DateCellTemplate/TableDatePicker';
import { ReactComponent as DatePickerIcon } from 'src/shared/assets/icons/kit/date-picker.svg';
import { isTimeStamp, isValidDate } from 'src/shared/lib/utils';

export interface MyDateCell extends Omit<DateCell, 'date'> {
  date?: string | number;
  dateOpen?: string;
  dateKey?: string;
}

const getFormattedTimeUnit = (timeUnit: number): string => timeUnit.toString().padStart(2, '0');

const getFormattedDate = (date: Date) => {
  const year = getFormattedTimeUnit(date.getFullYear());
  const month = getFormattedTimeUnit(date.getMonth() + 1);
  const day = getFormattedTimeUnit(date.getDate());

  return `${day}-${month}-${year}`;
};

export class DateCellTemplate implements CellTemplate<MyDateCell> {
  private wasEscKeyPressed = false;

  getCompatibleCell(uncertainCell: Uncertain<MyDateCell>): Compatible<MyDateCell> {
    if (!uncertainCell.date) {
      return { ...uncertainCell, date: '', value: 0, text: '' };
    }

    if (isTimeStamp(uncertainCell.date)) {
      const myDate = new Date(+uncertainCell?.date * 1000 || '');
      const text = getFormattedDate(myDate);

      return { ...uncertainCell, value: 0, text };
    }

    return { ...uncertainCell, value: 0, text: uncertainCell.date.toString() };
  }

  handleKeyDown(
    cell: Compatible<MyDateCell>,
    keyCode: number,
    ctrl: boolean,
    shift: boolean,
    alt: boolean,
    key: string,
    capsLock: boolean,
  ): { cell: Compatible<MyDateCell>; enableEditMode: boolean } {
    if (ctrl) {
      return { cell, enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER };
    }

    if (!ctrl && isCharAlphaNumeric(getCharFromKey(key))) {
      return {
        cell: this.getCompatibleCell({
          ...cell,
          dateOpen: cell.dateOpen === cell.dateKey ? undefined : cell.dateKey,
        }),
        enableEditMode: true,
      };
    }

    return {
      cell: {
        ...cell,
        dateOpen: cell.dateOpen === cell.dateKey ? undefined : cell.dateKey,
      },
      enableEditMode: keyCode === keyCodes.POINTER || keyCode === keyCodes.ENTER,
    };
  }

  update(
    cell: Compatible<MyDateCell>,
    cellToMerge: UncertainCompatible<MyDateCell>,
  ): Compatible<MyDateCell> {
    return this.getCompatibleCell({
      ...cell,
      dateOpen: cellToMerge.dateOpen,
      dateKey: cellToMerge.dateKey,
      date: cellToMerge.text || cellToMerge.date,
    });
  }

  getClassName(cell: Compatible<MyDateCell>, isInEditMode: boolean): string {
    return cell.className ? cell.className : '';
  }

  render(
    cell: Compatible<MyDateCell>,
    isInEditMode: boolean,
    onCellChanged: (cell: Compatible<MyDateCell>, commit: boolean) => void,
  ): ReactNode {
    const myDate = new Date(cell?.date || '');

    if (!isInEditMode) {
      return (
        <>
          {cell.text}
          <div
            {...(cell.dateKey === cell.dateOpen && { id: 'date-picker' })}
            className={
              'group absolute right-0 top-0 flex aspect-square h-full cursor-pointer ' +
              'items-center justify-center'
            }
            onClick={() => {
              onCellChanged(
                this.getCompatibleCell({ ...cell, dateOpen: cell.dateKey }),
                !this.wasEscKeyPressed,
              );
            }}
          >
            <DatePickerIcon
              className={cn(
                {
                  'fill-white group-hover:fill-blue-300': !Boolean(cell.dateKey === cell.dateOpen),
                  'fill-blue-300': Boolean(cell.dateKey === cell.dateOpen),
                },
                'h-4 w-4',
              )}
            />
          </div>
          {cell.dateKey === cell.dateOpen && (
            <TableDatePicker
              markedDate={myDate && isValidDate(myDate) ? myDate : new Date()}
              onClose={() => {
                onCellChanged(
                  this.getCompatibleCell({ ...cell, dateOpen: undefined }),
                  !this.wasEscKeyPressed,
                );
              }}
              onClick={date => {
                onCellChanged(
                  this.getCompatibleCell({
                    ...cell,
                    dateOpen: undefined,
                    date: getFormattedDate(date),
                  }),
                  !this.wasEscKeyPressed,
                );
              }}
            />
          )}
        </>
      );
    }

    return (
      <div className="relative h-full w-full">
        <input
          className="rg-input"
          ref={input => {
            if (input) input.focus();
          }}
          defaultValue={cell.text}
          onChange={e => {
            if (e.currentTarget.value) {
              onCellChanged(
                this.getCompatibleCell({ ...cell, date: e.currentTarget.value }),
                false,
              );
            }
          }}
          onBlur={e => {
            if (e.currentTarget.value) {
              onCellChanged(
                this.getCompatibleCell({ ...cell, date: e.currentTarget.value }),
                !this.wasEscKeyPressed,
              );
              this.wasEscKeyPressed = false;
            }
          }}
          onKeyDown={e => {
            if (e.keyCode === keyCodes.ESCAPE) this.wasEscKeyPressed = true;
          }}
          onCopy={e => e.stopPropagation()}
          onCut={e => e.stopPropagation()}
          onPaste={e => e.stopPropagation()}
          onPointerDown={e => e.stopPropagation()}
        />
      </div>
    );
  }
}
