import { ChangeEvent, FC, memo, useContext, useState } from 'react';
import cn from 'classnames';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import { TableContext } from 'src/app/providers/TableProvider';
import { AddRowsModal } from 'src/entity/AddRowsModal/ui';
import { ReactComponent as CloseIcon } from 'src/shared/assets/icons/kit/close.svg';
import { ReactComponent as EditIcon } from 'src/shared/assets/icons/kit/edit.svg';
import { ReactComponent as LoupeIcon } from 'src/shared/assets/icons/kit/loupe.svg';
import { debounce, declOfNum } from 'src/shared/lib/utils';
import { ActionsType } from 'src/shared/store/table/types';
import { UserContext } from 'src/shared/store/user';
import { Button, Input } from 'src/shared/ui';
import { Loader } from 'src/shared/ui/Loader';

import { Table } from '../../../feature/Table/ui';

import 'tippy.js/dist/tippy.css';

interface ProjectTablePageProps {
  isOpenedProjectList: boolean;
}

export const ProjectTablePage: FC<ProjectTablePageProps> = memo(({ isOpenedProjectList }) => {
  const projectListWidth = 'ml-[19rem]';

  const { tableSettings, isTableSettingsLoading } = useContext(ProjectsContext);
  const { user } = useContext(UserContext);
  const { resetTableData, saveRows, tableData, setTableData, isSaveLoading, setSearch } =
    useContext(TableContext);

  const [isAddRowsModalOpen, setIsAddRowsModalOpen] = useState(false);

  const handleOpenAddRowsModal = () => setIsAddRowsModalOpen(true);
  const handleCloseAddRowsModal = () => setIsAddRowsModalOpen(false);

  if (!tableSettings && !isTableSettingsLoading) {
    return (
      <div className="flex w-full items-center justify-center">
        <div className="font-gothampro-500 text-lg text-white">Форма не выбрана!</div>
      </div>
    );
  }

  if (!tableSettings && isTableSettingsLoading) {
    return (
      <div className="flex w-full items-center justify-center">
        <Loader classNames="w-40 h-40" />
      </div>
    );
  }
  const checkAllEdit = () =>
    !tableData.find(el => !el?.['actions']?.['isEdit'] && !el?.['actions']?.['newId']);

  const setEditAll = () =>
    setTableData(prev =>
      prev.map(el => ({
        ...el,
        actions: {
          ...(el.actions as ActionsType),
          isEdit: el?.id ? !checkAllEdit() : false,
        },
      })),
    );

  const handleSearch = debounce((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  }, 500);

  return (
    tableSettings && (
      <>
        <div className="flex w-full flex-col">
          <div className={cn('flex-shrink-0', projectListWidth)}>
            <div className="font-gothampro-500 mb-2.5 flex h-14 items-center text-lg text-white">
              {tableSettings?.alias || ''}
            </div>
            <div className="mb-2.5 flex w-full items-center">
              <div className="mr-5 w-2/3">
                <Input
                  onChange={handleSearch}
                  placeholder="Поиск"
                  postfixNode={
                    <div className="flex aspect-square h-full cursor-pointer items-center justify-center">
                      <LoupeIcon className="h-4 w-4 fill-white" />
                    </div>
                  }
                />
              </div>
              {tableData?.length ? (
                <div className="ml-auto">
                  <div className="font-gothampro-400 mb-1 text-extra-xs text-white opacity-50">
                    Всего строк
                  </div>
                  <div className="flex items-center">
                    <div
                      className={
                        'font-gothampro-400 min-w-12 border border-blue-400 bg-blue-500 ' +
                        'mr-2.5 rounded px-2 py-1.5 text-center text-xs text-white'
                      }
                    >
                      {tableData.length}
                    </div>
                    <div className="font-gothampro-400 text-xs text-white">
                      {declOfNum(tableData.length, ['строка', 'строки', 'строк'])}
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>

          <div
            className={cn(
              { [projectListWidth]: isOpenedProjectList },
              'mb-2.5 flex-1 rounded-lg border border-blue-400 bg-blue-600 p-5',
            )}
          >
            <Table
              isOpenedProjectList={isOpenedProjectList}
              tableSettings={tableSettings}
              height={`calc(100dvh - ${user?.isDeveloper ? 274 : 360}px)`}
            />
          </div>

          {!user?.isDeveloper && (
            <div
              className={cn({ [projectListWidth]: isOpenedProjectList }, 'flex flex-shrink-0 py-4')}
            >
              <Button
                classNames="w-60 mr-5"
                variant="blueBorder"
                onClick={handleOpenAddRowsModal}
                disabled={isSaveLoading}
              >
                Новая запись
                <CloseIcon className="ml-3 h-4 w-4 rotate-45 stroke-white" />
              </Button>

              <Button
                classNames="w-60 mr-auto"
                variant="blueBorder"
                onClick={setEditAll}
                disabled={isSaveLoading}
              >
                Редактировать все
                <EditIcon className="ml-3 h-4 w-4 fill-white" />
              </Button>

              <Button
                classNames="w-60 mr-5"
                variant="orange"
                onClick={saveRows}
                disabled={isSaveLoading}
              >
                Сохранить
              </Button>

              <Button
                classNames="w-60"
                variant="grey"
                onClick={resetTableData}
                disabled={isSaveLoading}
              >
                Отменить
              </Button>
            </div>
          )}
        </div>

        {isAddRowsModalOpen && <AddRowsModal onClose={handleCloseAddRowsModal} />}
      </>
    )
  );
});
