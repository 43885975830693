import { IProjectsContext } from './types';

export const projectsContextDefaultState: IProjectsContext = {
  projects: [],
  setProjects: () => {},
  isProjectsLoading: true,
  setIsProjectsLoading: () => {},

  forms: [],
  setForms: () => {},
  isFormsLoading: true,
  setIsFormsLoading: () => {},

  tableSettings: null,
  setTableSettings: () => {},
  isTableSettingsLoading: false,
  setIsTableSettingsLoading: () => {},

  selectedProject: null,
  setSelectedProject: () => {},

  selectedForm: null,
  setSelectedForm: () => {},
};
