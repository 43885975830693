export const emptyError = 'Поле не может быть пустым';

export enum routesEnum {
  LOGIN = '/login',
  TABLE = '/table',
}

export enum sortEnum {
  ASC = 'asc',
  DESC = 'desc',
}
