import { useContext, useEffect } from 'react';
import { TableContext } from 'src/app/providers/TableProvider';

export const useCheckAddRow = () => {
  const { addEmptyRows } = useContext(TableContext);

  const checkAddRow = e => {
    if ((e.ctrlKey && e.keyCode === 13) || e.keyCode === 45) {
      addEmptyRows(1);
    }
  };

  useEffect(() => {
    document.addEventListener('keydown', checkAddRow);

    return () => {
      document.removeEventListener('keydown', checkAddRow);
    };
  }, []);
};
