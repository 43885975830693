export const isDevApi = process.env.REACT_APP_BUILD === 'development';
// export const isDevApi = true;

export const AUTH_URL = isDevApi
  ? 'https://dis-back-dev.bots.winsolutions.ru/'
  : 'https://dis-back-stage.drawbot.winsolutions.ru/';

export const PROJECTS_URL = isDevApi
  ? 'https://dis-back2-dev.bots.winsolutions.ru/'
  : 'https://dis-back2-stage.drawbot.winsolutions.ru/';

export const PROJECT_URL = isDevApi
  ? 'https://dis-back3-dev.bots.winsolutions.ru/'
  : 'https://dis-back3-stage.drawbot.winsolutions.ru/';

export const TABLE_SOCKET_URL = isDevApi
  ? 'wss://dis-back2-dev.bots.winsolutions.ru/ws'
  : 'wss://dis-back2-stage.drawbot.winsolutions.ru/ws';
