import { FC, ReactNode } from 'react';
import { ProtectedContainer } from 'src/app/containers/ProtectedContainer';

interface ProtectedRouteProps {
  children: ReactNode;
}

export const ProtectedRoute: FC<ProtectedRouteProps> = ({ children }) => {
  return <ProtectedContainer>{children}</ProtectedContainer>;
};
