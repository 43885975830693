import { FC, MouseEvent, useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { TableContext } from 'src/app/providers/TableProvider';
import { AddRowsForm } from 'src/entity/AddRowsModal/types';
import { emptyError } from 'src/shared/const';
import { sleep } from 'src/shared/lib/utils';
import { Button, Input, Modal } from 'src/shared/ui';

interface AddRowsModalProps {
  onClose: () => void;
}

export const AddRowsModal: FC<AddRowsModalProps> = ({ onClose }) => {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<AddRowsForm>();

  const { addEmptyRows } = useContext(TableContext);

  const handleCancel = async (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onClose();
  };

  const onSubmit: SubmitHandler<AddRowsForm> = async ({ count }) => {
    addEmptyRows(count);
    onClose();

    const tableContainer = document.querySelector('#table-container');

    if (tableContainer) {
      const top = tableContainer.scrollHeight - 100;
      await sleep(100);
      tableContainer.scrollTo({ top });
    }
  };

  return (
    <Modal onClose={onClose}>
      <form className="flex w-full flex-col items-center" onSubmit={handleSubmit(onSubmit)}>
        <div className="font-gothampro-500 mb-5 text-center text-xl text-blue-300">
          Новая запись
        </div>
        <div className="font-gothampro-400 mb-5 text-center text-sm text-white">
          Чтобы создать новую запись введите необходимое количнство строк
        </div>

        <Input
          containerClassNames="w-48"
          type="number"
          placeholder="Введите значение"
          error={errors.count?.message}
          {...register(`count`, { required: emptyError })}
        />

        <div className="mt-10 flex">
          <Button variant="orange" disabled={isSubmitting} classNames="mr-5 w-60" type="submit">
            Создать
          </Button>
          <Button variant="grey" classNames="w-60" onClick={handleCancel}>
            Отмена
          </Button>
        </div>
      </form>
    </Modal>
  );
};
