import { TokenType } from 'src/shared/types';

import { localStorageKey } from './constants';

const getRefreshToken = (): string | undefined => {
  const data = localStorage.getItem(localStorageKey);

  if (!data) {
    return;
  }

  const token = JSON.parse(data);

  return token?.refresh_token;
};

const getAccessToken = (): string | undefined => {
  const data = localStorage.getItem(localStorageKey);

  if (!data) {
    return;
  }

  const token = JSON.parse(data);
  return token?.access_token;
};

const getTokens = (): TokenType | undefined => {
  const data = localStorage.getItem(localStorageKey);

  if (!data) {
    return;
  }

  const token = JSON.parse(data);

  if (!token?.refresh_token || !token?.access_token) {
    return;
  }

  return {
    access_token: token.access_token,
    refresh_token: token.refresh_token,
  };
};

const setTokens = (token: TokenType) => {
  localStorage.setItem(localStorageKey, JSON.stringify({ ...token }));
};

const removeTokens = () => {
  localStorage.removeItem(localStorageKey);
};

const TokenService = {
  getTokens,
  getAccessToken,
  getRefreshToken,
  removeTokens,
  setTokens,
};

export default TokenService;
