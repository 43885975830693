import { FC, useContext, useState } from 'react';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import { ProjectSettings } from 'src/feature/ProjectSettings/ui';
import { UserContext } from 'src/shared/store/user';
import { ProjectList } from 'src/widgets/ProjectList';
import { ProjectTablePage } from 'src/widgets/ProjectTable/ui';

export const TablePage: FC = () => {
  const { tableSettings } = useContext(ProjectsContext);
  const { user } = useContext(UserContext);

  const [isOpenedProjectList, setIsOpenProjectList] = useState<boolean>(true);
  const [isOpenedProjectSettings, setIsOpenProjectSettings] = useState<boolean>(false);

  return (
    <div className="relative flex w-full" style={{ height: 'calc(100dvh - 112px)' }}>
      <ProjectList
        isOpenedProjectList={isOpenedProjectList}
        setIsOpenProjectList={setIsOpenProjectList}
        classNames="absolute top-0 left-0"
      />

      <ProjectTablePage isOpenedProjectList={isOpenedProjectList} />
      {tableSettings && !user?.isOperator && (
        <ProjectSettings
          isOpenedProjectSettings={isOpenedProjectSettings}
          setIsOpenProjectSettings={setIsOpenProjectSettings}
          classNames="absolute top-0 right-0"
        />
      )}
    </div>
  );
};
