import React, { FC, memo, useContext, useEffect, useMemo, useState } from 'react';
import cn from 'classnames';
import { ProjectsContext } from 'src/app/providers/ProjectProvider';
import { ProjectListItem } from 'src/entity/ProjectListItem';
import { CreateProjectModal } from 'src/feature/CreateProjectModal';
import ProjectsService from 'src/shared/api/projects/ProjectsService';
import { ReactComponent as ArrowSmallIcon } from 'src/shared/assets/icons/kit/arrow-small.svg';
import { UserContext } from 'src/shared/store/user';
import { StateActionType } from 'src/shared/types';
import { Button, Input } from 'src/shared/ui';
import { Loader } from 'src/shared/ui/Loader';

interface ProjectListProps {
  isOpenedProjectList: boolean;
  setIsOpenProjectList: StateActionType<boolean>;
  classNames?: string;
}

export const ProjectList: FC<ProjectListProps> = memo(
  ({ isOpenedProjectList, setIsOpenProjectList, classNames }) => {
    const { projects, setProjects, isProjectsLoading, setIsProjectsLoading } =
      useContext(ProjectsContext);
    const { user } = useContext(UserContext);
    const [search, setSearch] = useState<string>('');
    const [isCreateProjectModalOpen, setIsCreateProjectModalOpen] = useState<boolean>(false);

    const handleOpenCreateProjectModal = () => setIsCreateProjectModalOpen(true);
    const handleCloseCreateProjectModal = () => setIsCreateProjectModalOpen(false);

    useEffect(() => {
      (async () => {
        try {
          const { data } = await ProjectsService.getProjects();

          setProjects(data || []);
        } catch (e) {
        } finally {
          setIsProjectsLoading(false);
        }
      })();
    }, []);

    const filteredProjects = useMemo(() => {
      if (!setProjects) {
        return projects;
      }

      return projects.filter(({ project_name }) =>
        project_name.toLowerCase().includes(search.toLowerCase()),
      );
    }, [search, projects]);

    const handleOpenProjectList = () => setIsOpenProjectList(prev => !prev);

    return (
      <>
        <div
          className={cn(
            { 'h-14 overflow-hidden': !isOpenedProjectList, 'h-full': isOpenedProjectList },
            'flex w-72 flex-col rounded bg-blue-600',
            classNames,
          )}
        >
          <div
            className="flex h-14 flex-shrink-0 cursor-pointer items-center px-4"
            onClick={handleOpenProjectList}
          >
            <div className="font-gothampro-400 text-lg text-white">Мои проекты</div>
            <ArrowSmallIcon
              className={cn({ '-rotate-90': isOpenedProjectList }, 'ml-auto h-3 w-3 stroke-white')}
            />
          </div>

          {isOpenedProjectList && (
            <>
              <div className="w-full flex-shrink-0 border-t border-blue-300 px-4 py-2.5">
                <Input placeholder="Поиск" onChange={e => setSearch(e.target.value)} />
              </div>

              <div className="flex flex-1 flex-col overflow-auto border-t border-blue-400">
                {!projects.length && isProjectsLoading ? (
                  <div className="flex h-28 w-full items-center justify-center">
                    <Loader classNames="w-10 h-10" />
                  </div>
                ) : null}

                {!projects.length && !isProjectsLoading ? (
                  <div className="flex h-14 min-h-14 w-full items-center justify-center">
                    <div className="font-gothampro-400 tex-sm text-white">Проекты не найдены</div>
                  </div>
                ) : null}

                {projects.length && !isProjectsLoading
                  ? filteredProjects.map((project, i) => (
                      <ProjectListItem key={`project-${i}`} project={project} />
                    ))
                  : null}
              </div>

              {user?.isAdmin && (
                <div className="w-full flex-shrink-0 p-4">
                  <Button variant="blue" onClick={handleOpenCreateProjectModal}>
                    Новый проект
                  </Button>
                </div>
              )}
            </>
          )}
        </div>

        {isCreateProjectModalOpen && <CreateProjectModal onClose={handleCloseCreateProjectModal} />}
      </>
    );
  },
);
