import { ButtonHTMLAttributes, FC } from 'react';
import cn from 'classnames';
import { includesCN } from 'src/shared/lib/utils';
import { ToolTip } from 'src/shared/ui';

import {
  ButtonSizesObjType,
  ButtonSizesType,
  ButtonVariablesObjType,
  ButtonVariablesType,
  ButtonVariantsObjType,
  ButtonVariantsType,
} from './types';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  tooltip?: string;
  variant?: ButtonVariantsType;
  variable?: ButtonVariablesType;
  size?: ButtonSizesType;
  classNames?: string;
}

export const Button: FC<ButtonProps> = ({
  tooltip,
  variant = 'blue',
  variable = 'text',
  size = 'base',
  classNames,
  children,
  ...props
}) => {
  const variants: ButtonVariantsObjType = {
    blue: 'text-white bg-blue-100 hover:bg-blue-700',
    blueBorder: 'text-white bg-blue-400 border border-blue-300 hover:bg-blue-200',
    lightBlue: 'text-white bg-blue-300 hover:bg-blue-700',
    grey: 'text-white bg-grey-100 hover:bg-blue-400',
    orange: 'text-white bg-orange-100 hover:bg-orange-200',
  };

  const variables: ButtonVariablesObjType = {
    text: 'px-5',
    icon: 'aspect-square flex items-center justify-center',
  };

  const sizes: ButtonSizesObjType = {
    base: cn(
      { 'w-full': !includesCN(classNames, 'w-') },
      'rounded flex items-center justify-center h-11',
    ),
    small: cn(
      { 'w-full': !includesCN(classNames, 'w-') },
      'rounded flex items-center justify-center h-8 text-xs',
    ),
  };

  const button = (
    <button
      className={cn(
        'font-gothampro-500 whitespace-nowrap transition-all',
        variants[variant],
        variables[variable],
        sizes[size],
        classNames,
      )}
      {...props}
    >
      {children}
    </button>
  );

  return tooltip ? <ToolTip label={tooltip}>{button}</ToolTip> : button;
};
