import { AxiosResponse } from 'axios';
import { ApiProjects } from 'src/shared/api/ApiProject';

import { BatchRowsData, BatchRowsParams, BatchRowsRes } from './types';

const batchRows = (params: BatchRowsParams) =>
  ApiProjects.post<string, Promise<AxiosResponse<BatchRowsRes>>, BatchRowsData>(
    `/table/${params.table_id}/batch`,
    params.data,
  );
const TableService = { batchRows };

export default TableService;
