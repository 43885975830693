import { ITableContext } from './types';

export const initNoticeData = {
  delete: 0,
  update: 0,
  create: 0,
};

export const tableContextDefaultState: ITableContext = {
  tableData: [],
  isSaveLoading: false,
  setTableData: () => {},
  resetTableData: () => {},
  addEmptyRows: () => {},
  setIsSaveLoading: () => {},
  saveRows: () => {},
  setSort: () => {},
  setSearch: () => {},
};
