import { useContext, useEffect, useState } from 'react';
import { TableContext } from 'src/app/providers/TableProvider';
import { ActionsType } from 'src/shared/store/table/types';

export const useCheckPasteAddRow = () => {
  const { tableData, setTableData, addEmptyRows } = useContext(TableContext);

  const [selectedRowId, setSelectedRowId] = useState<number | string>('');

  const checkPasteAddRow = event => {
    const htmlData = event.clipboardData.getData('text/html');
    const parsedData = new DOMParser().parseFromString(htmlData, 'text/html');

    const hasReactGridAttribute =
      document.body.firstElementChild?.getAttribute('data-reactgrid') === 'reactgrid-content';
    let rowsToPasteCount = 0;

    if (hasReactGridAttribute && document.body.firstElementChild?.firstElementChild) {
      rowsToPasteCount = parsedData.querySelectorAll('tr')?.length || 0;
    } else {
      const rows = event.clipboardData
        .getData('text/plain')
        .replace(/(\r\n)$/, '')
        .split('\n');
      rowsToPasteCount = rows?.length || 0;
    }
    setTableData(prev =>
      prev.map((el, i) => {
        if (+selectedRowId <= i && +selectedRowId + rowsToPasteCount > i) {
          return { ...el, actions: { ...(el.actions as ActionsType), isEdit: true } };
        }
        return el;
      }),
    );

    const rowsToInsert = tableData.length - +selectedRowId;
    const newRowsLength = rowsToInsert >= rowsToPasteCount ? 0 : rowsToPasteCount - rowsToInsert;

    addEmptyRows(newRowsLength);
  };

  useEffect(() => {
    const table = document.querySelector('#table-container');

    table?.addEventListener('paste', checkPasteAddRow);

    return () => {
      table?.removeEventListener('paste', checkPasteAddRow);
    };
  }, [selectedRowId]);

  return { setSelectedRowId };
};
