import { FC, ReactElement } from 'react';
import Tippy from '@tippyjs/react';

import './styles.css';

interface ToolTipProps {
  label?: string;
  children: ReactElement;
}

export const ToolTip: FC<ToolTipProps> = ({ label, children }) => {
  return (
    <Tippy
      appendTo="parent"
      animateFill={false}
      content={label}
      className="font-gilroy-400 rounded bg-blue-800 text-extra-xs"
    >
      {children}
    </Tippy>
  );
};
